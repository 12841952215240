$icon-shadow-primary: 0 1px 5px rgba(0, 0, 0, 0.85);
$icon-shadow-outline: 0 0 1px rgba(0, 0, 0, 0.6);
$text-shadow-primary: 0 1px 4px rgba(0, 0, 0, 0.65);
$text-shadow-outline: 0 0 1px rgba(0, 0, 0, 0.6);

.card {
  width: 167px;
  max-width: 230px;
  flex-grow: 1;
  height: 240px;
  border-radius: 8px;
  background: #25262b;
  object-fit: cover;
}
.loader {
  display: flex;
  width: 100%;
  height: 160px;
  align-items: center;
  justify-content: center;
}
.error {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: 160px;
  &__body {
    position: absolute;
    bottom: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  &__icon {
    margin-bottom: 13px;
  }
  &__text {
    color: #fa5252;
    font-size: 12px;
  }
}
.checkbox {
  pointer-events: auto;
  input {
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 60%);
    border: 1px solid white;
    background-color: transparent;
    border-radius: 4px;
    &:checked {
      border: 1px solid transparent;
    }
  }
}
.menu {
  pointer-events: auto;
  &__icon {
    color: white;
  }
}
.block {
  position: absolute;
  top: 0;
  padding: 12px;
  width: 100%;
  height: 160px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  pointer-events: none;
  &__readOnly {
    justify-content: flex-end;
    height: 183px;
  }
  &__hasDeleteButton {
    justify-content: space-between;
  }
}
.upblock {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  &__readOnly {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
    & svg {
      filter: drop-shadow($icon-shadow-primary);
      filter: drop-shadow($icon-shadow-outline);
    }
  }
  &__icon {
    pointer-events: auto;
  }

}
.menuWithShadow {
  svg {
    filter: $icon-shadow-primary;
    filter: $icon-shadow-outline;
  }
}

.downblock {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: end;
  pointer-events: auto;
  &__icons {
    display: flex;
    flex-direction: column;
    margin-left: -4px;
    & svg {
      filter: drop-shadow($icon-shadow-primary);
      filter: drop-shadow($icon-shadow-outline);
    }
  }
  &__time {
    font-size: 12px;
    color: white;
    text-shadow: $text-shadow-primary, $text-shadow-outline;
  }
}
.thumbnail {
  height: 160px;
  color: #c1c2c5;
  position: relative;
  background: #373a40;
  border-radius: 8px 8px 0 0;

  &:hover {
    //pointer-events: none;
      img:first-child {
        display: none;
      }
      img:last-child {
        display: block;
      }
  }

}
.cardImage {
  position: absolute;
  top: 0;
  left: 0;
  height: 160px;
  width: 100%;
  object-fit: cover;
}
.imgContainer {
  border-radius: 8px 8px 0 0;
  height: 160px;
  position: relative;
  overflow: hidden;
}
.content {
  display: flex;
  flex-direction: column;
  height: 80px;
  padding: 9px 12px 12px 16px;
  border-radius: 0 0 8px 8px;
  border-top: none;
  &__title {
    color: #c1c2c5;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18.27px;
    margin: 0;
    font-family: "Segoe UI", sans-serif;
    margin-bottom: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &__products {
    width: 100%;
    align-items: center;
    background: #00AD60;
    border-radius: 4px;
    border: none;
    height: 30px;
    padding: 0 12px;
    justify-content: center;
    color: #ffffff;
    gap: 8px;
    cursor: pointer;
    &:focus {
      outline: none;
      outline-offset: 0;
    }
    &:disabled {
      pointer-events: none;
    }

    &Default {
      background: rgba(193, 194, 197, 20%);
      span {
        color: #c1c2c5;
      }
    }
  }
  &__defColor {
    color: #c1c2c5;
  }
}

.dropdown {
  background-color: #25262b;
  border-radius: 4px;
  box-shadow: none;
  border: 1px solid #373a40;
  margin-left: -7px;
  &__item {
    display: flex;
    align-items: center;
    color: #c1c2c5;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    &:hover {
      background: rgba(23, 232, 133, 20%);
      color: #25262b;
    }
  }
}
.deleteBtn {
  &__icon {
    width: 24px;
    height: 24px;
    path {
      stroke: #fff;
      stroke-width: 1;
    }
  }
  &__text {
    color: #e03131;
  }
}
.iconGreen {
  color: #00ad60;
}
.iconDef {
  color: #c1c2c5;
}

.thumbnailHeight__readOnly {
  height: 183px;
}
.contentHeight__readOnly {
  height: 57px;
}
