.wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 12px;
    margin: 0 16px;

    .badge {
        &__active {
            background-color: rgba(23, 232, 133, 20%);
        }

        &__notInstalled {
            background-color: rgba(193, 194, 197, 20%);
        }

        &__notPublished {
            background-color: rgba(34, 139, 230, 20%);
        }

        &__error {
          background-color:#3E2324;
          color: #FA5252;
      }
    }

    .tooltip {
      text-align: center;
      pointer-events: auto;
      &__link {
         color: #00AD60;
         text-decoration: underline
      }
    }

    .icons {
        display: flex;
        align-items: center;
        gap: 4px;
    }
}

.iconWrapper {
  > svg {
    &:hover {
      background-color: #25262b;
      border-radius: 6px;
    }
  }
}
