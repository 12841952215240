.wrapper {
  width: 340px;
  min-width: 330px;
  max-height: 610px;

  &__carousel {
    max-height: 690px;
  }
}
.widgetWrapper {
  position: relative;
}
.widgetCenter {
  position: absolute;
  top: 50%;
}
