.select {
  &__virtualContainer {
    height: var(--scrollable-select-total-size);
    width: 100%;
    position: relative;
  }
}


.selectItem {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 12px 4px 10px;
  border-radius: 4px;
  cursor: pointer;
  height: 67px;

  &:not(:last-child) {
    margin-bottom: 4px;
  }

  &[data-selected="true"] {
    background-color: #3a3a3a; /* Replace with theme.colors.greyDark[3] */
  }

  &:hover {
    background-color: #3a3a3a; /* Replace with theme.colors.greyDark[3] */
  }
}

.selectItem__infoWrapper {
  display: flex;
  align-items: center;
}

.selectDropdown__buttonsWrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 12px 17px;
}
.badgesContainer {
  display: flex;
  flex-flow: column nowrap;
  gap: 4px;
  overflow: hidden;
  height: 100%;
  max-height: 100%;
}
