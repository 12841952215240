@use "sass:color";

.container {
  position: relative;
}

.image {
  object-fit: cover;
  width: 100%;
  height: 100%;
  display: block;

  &Hidden {
    visibility: hidden;
  }
}

.skeleton {
  position: absolute;
  inset: 0;
}

.placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #C1C2C5;
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;

  &Icon {
    width: 40%;
    height: 40%;
    color: color.adjust(#C1C2C5, $lightness: 10%);
  }
}
