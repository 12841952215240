.modalBody {
  padding: 0;
  overflow: hidden;
  height: 100%;
}

.modalInner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  & > section {
    max-height: 685px;
    height: 100%;
    width: 100%;
    max-width: 1045px;
  }

}

.modalRoot {
  height: 100%;
  max-height: 685px;
}

.root {
  display: flex;
  position: relative;
  height: 100%;
}


.video {
  width: 434px;
  //width: 100%;
  display: flex;
  flex-direction: column;
}

.closeButton {
  position: absolute;
  top: 18px;
  right: 18px;
  z-index: 2;
}

.header {
  color: #fff;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  margin: 0 0 24px;
  padding: 64px 64px 0;
}

.selectedProductListWrapper {
  padding: 0 64px;
  flex: 1;
}

.loader {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tags {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.buttons {
  margin-top: auto;
  padding: 35px 20px;
  display: flex;
  justify-content: flex-end;
  gap: 16px;
}

.btn {
  &__confirm {
    &[data-disabled] {
      pointer-events: all;
    }
  }
}


.title {
  text-transform: uppercase;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  white-space: normal;
  word-wrap: break-word;
  height: auto;
  max-height: none;
  overflow: hidden;
  font-weight: 500;
}

.badgesContainer {
  display: flex;
  flex-direction: column;
  gap: 4px;
  overflow: hidden;
  height: 100%;
  max-height: 100%;
}
